import React, { useEffect, useState } from "react";
import "./Header.scss";
import logOutIcon from "../assets/logo/logOut.png";
import leftArrow from "../assets/logo/leftArrow.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { logoutRequest } from "../redux/actions/AuthAction";

const Header = () => {
  const { user } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const [date, setDate] = useState(new Date());
  const navigate = useNavigate();
  const logout = () => {
    dispatch(logoutRequest());
  };
  useEffect(() => {
    let timer = setInterval(() => setDate(new Date()), 1000);
    return function() {
      clearInterval(timer);
    };
  });
  let previous = "";
  switch (window.location.pathname) {
    case "/pending-task":
      previous = "/dashboard";
      break;
    case "/task-detail":
      previous = "/pending-task";
      break;
    case "/task-form":
      previous = "/task-detail";
      break;
    case "/tool-unload":
      previous = "/task-form";
      break;
    case "/tool-unload-report":
      previous = "/tool-unload";
      break;
    default:
      previous = "dashboard";
  }
  const gotoPrevious = () => {
    navigate(previous);
  };
  return (
    <div className="main-header d-flex justify-content-between align-items-center">
      <div className="back-btn" onClick={gotoPrevious}>
        <img src={leftArrow} alt="" />
      </div>
      <div className="profile d-flex justify-content-between">
        <div className="info">
          <h2>{user?.username}</h2>
          <h5>ID : 09125</h5>
        </div>
        <div className="time">
          <h2>{dayjs(date).format("h.mm a")}</h2>
          <h5>{dayjs(date).format("ddd MMMM DD YYYY")}</h5>
        </div>
      </div>
      <div className="logout" onClick={logout}>
        <img src={logOutIcon} alt="" />
      </div>
    </div>
  );
};

export default Header;
