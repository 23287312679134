import * as types from "../actions/types";

const initialState = {
  token: null,
  user: null,
  loader: false,
  isLoggedIn: false,
  role: null,
  status: null,
  userId: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.INIT_APP:
      return { ...state, loader: false };
    case types.LOGIN_REQUEST:
      return { ...state, loader: true };
    case types.LOGIN_SUCCESS:
    case types.LOGIN_FAIL:
    case types.GET_USER_SUCCESS:
    case types.GET_USER_FAIL:
      return { ...state, ...(action.payload || {}), loader: false };
    case types.LOGOUT_REQUEST:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
