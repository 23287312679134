import React, { useState } from "react";
import { Button, Dropdown, Form, Table } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import "./ToolUnload.scss";
import Header from "../../components/Header";

const dropdownItems = ["item1", "item2"];

const ToolUnload = () => {
  const [dropdownItem, setDropdownItem] = useState(null);
  const navigate = useNavigate();
  const toolUnloadReport = () => {
    navigate("/tool-unload-report");
  };
  return (
    <div className="tool-unload-screen">
      <Header />
      <div className="tool-unload-header">Tool Unload Report</div>
      <Form className="tool-form">
        <div className="d-flex form-dropdown full-width d-flex justify-content-between">
          <Dropdown>
            <div className="label">Section</div>
            <Dropdown.Toggle variant="success">
              {dropdownItem || "Select Example"}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {dropdownItems.map((item) => (
                <Dropdown.Item key={item} onClick={() => setDropdownItem(item)}>
                  {item}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
          <Form.Group className="report-number">
            <Form.Label>Report Number</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Username"
              name="username"
            />
          </Form.Group>
        </div>
        <Table className="tool-unload-table" responsive>
          <thead>
            <tr>
              <th>Date</th>
              <th>Tool Name /number</th>
              <th>Used For Production</th>
              <th>Operator Name</th>
              <th>Production Quantity</th>
              <th>#</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <Form.Control type="date" placeholder="dd-mm-yyyy" />
              </td>
              <td>
                <Form.Control
                  type="text"
                  placeholder="Reason For Unloading Tool"
                />
              </td>
              <td>
                <Form.Control type="text" placeholder="Total Strokes" />
              </td>
              <td>
                <Form.Control type="text" placeholder="Total Strokes" />
              </td>
              <td>
                <Form.Control type="text" placeholder="Machine Used" />
              </td>
              <td>
                <Button className="delete-btn">
                  <Icon.XLg />
                </Button>
              </td>
            </tr>
          </tbody>
          <div className="footer">
            <Button className="add-more-btn">+ Add More</Button>
          </div>
        </Table>
      </Form>
      <div className="d-flex">
        <Form.Group
          className="textarea"
          controlId="exampleForm.ControlTextarea1"
        >
          <Form.Label>Production Remark</Form.Label>
          <Form.Control as="textarea" placeholder="Write remark" rows={3} />
        </Form.Group>
        <Form.Group
          className="textarea"
          controlId="exampleForm.ControlTextarea2"
        >
          <Form.Label>Tool Room Comments</Form.Label>
          <Form.Control as="textarea" placeholder="Write Comment" rows={3} />
        </Form.Group>
      </div>
      <div className="footer-btn">
        <Button className="save-btn" onClick={toolUnloadReport}>
          Save
        </Button>
      </div>
    </div>
  );
};

export default ToolUnload;
