import { put, call, takeLatest } from "redux-saga/effects";
import * as types from "../actions/types";
import * as AppService from "../services/AppService";
import * as AppAction from "../actions/AppAction";

export default function* sagas() {
  function* getUserInfo(action) {
    try {
      const { user_id } = action.payload || {};
      const res = yield call(AppService.getUserInfo, user_id);
      if (res) {
        yield put(AppAction.getUserInfoSuccess(res));
      } else {
        yield put(AppAction.getUserInfoFail(res?.message || "Invalid request"));
      }
    } catch (error) {
      console.log(error);
        yield put(
          AppAction.getUserInfoFail(
            error?.response?.data?.message ||
              "Something went wrong, please try again."
          )
        );
      }
    }
  yield takeLatest(types.GET_USER_REQUEST, getUserInfo);
}
