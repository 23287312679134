import React from "react";
import "./SelectLanguage.scss";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const SelectLanguage = () => {
  const navigate = useNavigate();
  const login = () => {
    navigate("/login");
  };
  return (
    <div className="select-language-screen">
      <div className="title">
        <div className="en">Select Language</div>
        <div className="hn">भाषा चयन करे</div>
      </div>
      <div className="language-btn">
        <Button className="en-btn" onClick={login}>
          ENGLISH
        </Button>
        <Button className="hn-btn" onClick={login}>
          हिंदी
        </Button>
      </div>
    </div>
  );
};

export default SelectLanguage;
