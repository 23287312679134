import React, { useState } from "react";
import { Button, Dropdown, Form, Table } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import "./ToolUnloadReport.scss";
import Header from "../../components/Header";

const dropdownItems = ["item1", "item2"];

const ToolUnloadReport = () => {
  const [dropdownItem, setDropdownItem] = useState(null);
  const navigate = useNavigate();
  const pendingTask = () => {
    navigate("/pending-task");
  };
  return (
    <div className="tool-unload-report-screen">
      <Header />
      <div className="tool-unload-report-header">Tool Unload Report</div>
      <Form className="tool-form">
        <div className="d-flex form-dropdown full-width d-flex justify-content-between">
          <Form.Group className="form-group">
            <Form.Label>Enter Date</Form.Label>
            <Form.Control type="date" placeholder="dd-mm-yyyy" />
          </Form.Group>
          <Dropdown>
            <div className="label">Select Section</div>
            <Dropdown.Toggle variant="success">
              {dropdownItem || "Select Example"}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {dropdownItems.map((item) => (
                <Dropdown.Item key={item} onClick={() => setDropdownItem(item)}>
                  {item}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
          <Form.Group className="form-group">
            <Form.Label>Report Number</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Username"
              name="username"
            />
          </Form.Group>
        </div>
        <div className="tool-detail" responsive>
          <div className="heading">Tool Details</div>
          <div className="body">
            <div className="d-flex gap-4">
              <Form.Group className="form-group">
                <Form.Label>Tool Name</Form.Label>
                <Form.Control type="text" placeholder="Enter Tool Name" />
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label>Strip Size</Form.Label>
                <Form.Control type="text" placeholder="Enter Strip Size" />
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label>Pitch</Form.Label>
                <Form.Control type="text" placeholder="Enter Pitch" />
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label>RM Grade</Form.Label>
                <Form.Control type="text" placeholder="Enter Rm grade" />
              </Form.Group>
            </div>
            <div className="d-flex gap-4 mt-3">
              <Form.Group className="form-group form-group-lg">
                <Form.Label>Production Qtd</Form.Label>
                <Form.Control type="text" placeholder="Enter Production Qtd" />
              </Form.Group>
              <Form.Group className="form-group form-group-lg">
                <Form.Label>Tool Perimeters</Form.Label>
                <Form.Control type="text" placeholder="Enter Perimeters" />
              </Form.Group>
              <Dropdown>
                <div className="label">Customer </div>
                <Dropdown.Toggle variant="success">
                  {dropdownItem || "select Customer Name"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {dropdownItems.map((item) => (
                    <Dropdown.Item
                      key={item}
                      onClick={() => setDropdownItem(item)}
                    >
                      {item}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
        <div className="tool-detail" responsive>
          <div className="heading">Machine Details</div>
          <div className="body">
            <div className="d-flex gap-4">
              <Form.Group className="form-group">
                <Form.Label>Machined Used</Form.Label>
                <Form.Control type="text" placeholder="Machine Used" />
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label>RAM Alignment</Form.Label>
                <Form.Control type="text" placeholder="Enter RAM" />
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label>TDC</Form.Label>
                <Form.Control type="text" placeholder="Enter TDC" />
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label>BDC</Form.Label>
                <Form.Control type="text" placeholder="Enter BDC" />
              </Form.Group>
            </div>
            <div className="d-flex gap-4 mt-3">
              <Form.Group className="form-group form-group-lg">
                <Form.Label>Traveling</Form.Label>
                <Form.Control type="text" placeholder="Traveling" />
              </Form.Group>
              <Form.Group className="form-group form-group-lg">
                <Form.Label>Produced Qtd</Form.Label>
                <Form.Control type="text" placeholder="Enter Perimeters" />
              </Form.Group>
              <Dropdown>
                <div className="label">Operator Name </div>
                <Dropdown.Toggle variant="success">
                  {dropdownItem || "select Operator Name"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {dropdownItems.map((item) => (
                    <Dropdown.Item
                      key={item}
                      onClick={() => setDropdownItem(item)}
                    >
                      {item}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
        <div className="tool-detail" responsive>
          <div className="heading">Critical QC Perimeters</div>
          <div className="body">
            <div className="d-flex gap-4">
              <Form.Group className="form-group">
                <Form.Label>Rotor OD</Form.Label>
                <Form.Control type="text" placeholder="Rotor OD" />
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label>Rotor ID</Form.Label>
                <Form.Control type="text" placeholder="Rotor ID" />
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label>Stator OD</Form.Label>
                <Form.Control type="text" placeholder="Enter OD" />
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label>Stator ID</Form.Label>
                <Form.Control type="text" placeholder="Stator ID" />
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label>Skew Angle</Form.Label>
                <Form.Control type="text" placeholder="Skew Angle" />
              </Form.Group>
            </div>
          </div>
        </div>
      </Form>
      <div className="d-flex">
        <Form.Group
          className="textarea"
          controlId="exampleForm.ControlTextarea1"
        >
          <Form.Label>Production Remark</Form.Label>
          <Form.Control as="textarea" placeholder="Write remark" rows={3} />
        </Form.Group>
        <Form.Group
          className="textarea"
          controlId="exampleForm.ControlTextarea2"
        >
          <Form.Label>Others</Form.Label>
          <Form.Control as="textarea" placeholder="Other If/any" rows={3} />
        </Form.Group>
      </div>
      <div className="footer-btn">
        <Button className="save-btn" onClick={pendingTask}>
          Save
        </Button>
      </div>
    </div>
  );
};

export default ToolUnloadReport;
