import React, { useState } from "react";
import { Button, Dropdown, Form } from "react-bootstrap";
import formIcon from "../../assets/logo/form.png";
import { useNavigate } from "react-router-dom";
import "./TaskForm.scss";
import Header from "../../components/Header";

const dropdownItems = ["item1", "item2"];

const TaskForm = () => {
  const [dropdownItem, setDropdownItem] = useState(null);
  const navigate = useNavigate();
  const toolUnload = () => {
    navigate("/tool-unload");
  };
  return (
    <div className="task-form-screen">
      <Header />
      <div className="task-detail-header d-flex justify-content-between">
        <h2>
          Status : <span>Not Started</span>
        </h2>
        <h2>
          Due on : <span>Today</span>
        </h2>
      </div>
      <Form className="task-form">
        <div className="title">
          <img src={formIcon} alt="" /> Fill The Form
        </div>
        <div className="d-flex form-dropdown full-width">
          <Dropdown>
            <div className="label">Heading</div>
            <Dropdown.Toggle variant="success">
              {dropdownItem || "Select Example"}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {dropdownItems.map((item) => (
                <Dropdown.Item key={item} onClick={() => setDropdownItem(item)}>
                  {item}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown>
            <div className="label">Heading</div>
            <Dropdown.Toggle variant="success">
              {dropdownItem || "Select Example"}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {dropdownItems.map((item) => (
                <Dropdown.Item key={item} onClick={() => setDropdownItem(item)}>
                  {item}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown>
            <div className="label">Heading</div>
            <Dropdown.Toggle variant="success">
              {dropdownItem || "Select Example"}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {dropdownItems.map((item) => (
                <Dropdown.Item key={item} onClick={() => setDropdownItem(item)}>
                  {item}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="d-flex form-dropdown input-lg">
          <Dropdown>
            <div className="label">Heading</div>
            <Dropdown.Toggle variant="success">
              {dropdownItem || "Select Example"}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {dropdownItems.map((item) => (
                <Dropdown.Item key={item} onClick={() => setDropdownItem(item)}>
                  {item}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown>
            <div className="label">Heading</div>
            <Dropdown.Toggle variant="success">
              {dropdownItem || "Select Example"}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {dropdownItems.map((item) => (
                <Dropdown.Item key={item} onClick={() => setDropdownItem(item)}>
                  {item}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <Form.Group
          className="textarea"
          controlId="exampleForm.ControlTextarea1"
        >
          <Form.Label>Example textarea</Form.Label>
          <Form.Control as="textarea" placeholder="Select Example" rows={3} />
        </Form.Group>
      </Form>
      <div className="footer-btn">
        <Button className="save-btn" onClick={toolUnload}>
          Save
        </Button>
      </div>
    </div>
  );
};

export default TaskForm;
