import React, { useEffect } from "react";
import "./LoginScreen.scss";
import { Button, Form, Spinner } from "react-bootstrap";
import { loginRequest } from "../../redux/actions/AuthAction";
import { loginValidation } from "../../validation/loginValidation";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";

const LoginScreen = () => {
  const dispatch = useDispatch();
  const { loader } = useSelector((state) => state.app);
  const initialValues = {
    username: "",
    password: "",
  };
  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {},
    validationSchema: loginValidation,
  });
  const {
    isValid,
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
    setFieldTouched,
    validateForm,
  } = formik;
  const doLogin = () => {
    if (isValid) dispatch(loginRequest(values.username, values.password));
    else Object.keys(errors).map((k) => setFieldTouched(k, true));
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    if (isValid) doLogin();
  };
  useEffect(() => {
    validateForm();
  }, []);
  return (
    <div className="login-screen">
      <div className="heading">Login to your Account</div>
      <Form className="login-form" onSubmit={handleSubmit}>
        <Form.Control
          type="text"
          placeholder="Enter Username"
          name="username"
          value={values.username}
          onBlur={handleBlur}
          onChange={handleChange}
        />
        {errors.username && touched.username && (
          <p className="form-error">{errors.username}</p>
        )}
        <Form.Control
          type="password"
          placeholder="Password"
          name="password"
          value={values.password}
          onBlur={handleBlur}
          onChange={handleChange}
        />
        {errors.password && touched.password && (
          <p className="form-error">{errors.password}</p>
        )}
        <Button
          disabled={loader}
          type="submit"
          className="login-btn"
          onClick={doLogin}
        >
          {loader && <Spinner as="span" animation="grow" />}
          Login
        </Button>
      </Form>
    </div>
  );
};

export default LoginScreen;
