import {
  BrowserRouter,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import "./css/App.scss";
import "bootstrap/dist/css/bootstrap.min.css";

import SelectLanguage from "./screens/Authentication/SelectLanguage";
import LoginScreen from "./screens/Authentication/LoginScreen";
import HomeScreen from "./screens/Home/HomeScreen";
import PendingTask from "./screens/Task/PendingTask";
import TaskDetail from "./screens/Task/TaskDetail";
import TaskForm from "./screens/Task/TaskForm";
import ToolUnload from "./screens/Tool/ToolUnload";
import ToolUnloadReport from "./screens/Tool/ToolUnloadReport";
import { useSelector } from "react-redux";
import { useEffect } from "react";

const AppLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isLoggedIn } = useSelector((state) => state.app);
  useEffect(() => {
    if (location.pathname !== "/login" && !isLoggedIn) {
      navigate("/login");
    }
  }, [location, isLoggedIn, navigate]);
  return (
    <div className="container-width">
      <Outlet />
    </div>
  );
};

const AuthLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isLoggedIn } = useSelector((state) => state.app);
  useEffect(() => {
    if (location.pathname === "/login" && isLoggedIn) {
      navigate("/dashboard");
    }
  }, [location, isLoggedIn, navigate]);
  return (
    <div className="container-width">
      <Outlet />
    </div>
  );
};

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<AppLayout />}>
          <Route path="/" element={<SelectLanguage />} />
          <Route path="/dashboard" element={<HomeScreen />} />
          <Route path="/pending-task" element={<PendingTask />} />
          <Route path="/task-detail" element={<TaskDetail />} />
          <Route path="/task-form" element={<TaskForm />} />
          <Route path="/tool-unload" element={<ToolUnload />} />
          <Route path="/tool-unload-report" element={<ToolUnloadReport />} />
        </Route>
        <Route path="/" element={<AuthLayout />}>
          <Route exact path="/login" element={<LoginScreen />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
