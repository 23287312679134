import React from "react";
import { Button } from "react-bootstrap";
import taskIcon from "../../assets/logo/task.png";
import toolIcon from "../../assets/logo/tool.png";
import remarkIcon from "../../assets/logo/remark.png";
import { useNavigate } from "react-router-dom";
import "./TaskDetail.scss";
import Header from "../../components/Header";

const TaskDetail = () => {
  const navigate = useNavigate();

  const taskForm = () => {
    navigate("/task-form");
  };
  return (
    <div className="task-detail-screen">
      <Header />
      <div className="task-detail-header d-flex justify-content-between">
        <h2>
          Status : <span>Not Started</span>
        </h2>
        <h2>
          Due on : <span>Today</span>
        </h2>
      </div>
      <div className="detail-card">
        <div className="card">
          <div className="card-title">
            <img src={taskIcon} alt="" /> Task Details
          </div>
          <div className="card-data">
            <div className="d-flex">
              <div className="title mb-3">Task Name :</div>
              <div className="content mb-3">Breakdown Maintenance</div>
            </div>
            <div className="d-flex">
              <div className="title mb-3">Assigned On:</div>
              <div className="content mb-3">04-03-2023 12:23PM</div>
            </div>
            <div className="d-flex">
              <div className="title mb-3">Assigned By :</div>
              <div className="content mb-3">Rajes J. (Supervisor)</div>
            </div>
            <div className="d-flex">
              <div className="title mb-3">Due Date :</div>
              <div className="content mb-3">05-03-2023</div>
            </div>
            <div className="d-flex">
              <div className="title">Priority :</div>
              <div className="content">High</div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-title">
            <img src={toolIcon} alt="" /> Tool Details
          </div>
          <div className="card-data">
            <div className="d-flex">
              <div className="title mb-3">Tool Number :</div>
              <div className="content mb-3">T-001</div>
            </div>
            <div className="d-flex">
              <div className="title mb-3">Tool Name :</div>
              <div className="content mb-3">Tool Name goes here</div>
            </div>
            <div className="d-flex">
              <div className="title mb-3">Machine No :</div>
              <div className="content mb-3">55</div>
            </div>
            <div className="title mb-02">What to be Done :</div>
            <div className="tool-content mb-0">
              <div className="tool-content-data">
                1. Describe what to be done with tool
              </div>
              <div className="tool-content-data">
                2. Describe what to be done with tool
              </div>
              <div className="tool-content-data">
                3. Describe what to be done with tool
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="remark-card">
        <div className="title">
          <img src={remarkIcon} alt="" /> Remark
        </div>
        <div className="content">
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum is simply dummy text of the printing and
          typesetting industry.
        </div>
      </div>
      <div className="footer-btn">
        <Button className="submit-btn" onClick={taskForm}>
          Submit Task
        </Button>
      </div>
    </div>
  );
};

export default TaskDetail;
