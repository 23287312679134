import { toast } from "react-toastify";
import * as API from "../services/Api";
import * as types from "./types";
import { initApp } from "./AppAction";

// Login Request
export const loginRequest = (username, password) => ({
  type: types.LOGIN_REQUEST,
  payload: { username, password },
});
export const loginSuccess = (data) => {
  return (dispatch) => {
    API.setHeaderAuthorization(data.access_token);
    dispatch({
      type: types.LOGIN_SUCCESS,
      payload: {
        token: data.access_token,
        role: data.access_token,
        status: data.status,
        userId: data.id,
        isLoggedIn: true,
      },
    });
    dispatch(initApp());
  };
};
export const loginFail = (error) => {
  return (dispatch) => {
    toast.error(error);
    dispatch({
      type: types.LOGIN_FAIL,
      payload: { error },
    });
  };
};

// Logout Request
export const logoutRequest = () => {
  return (dispatch) => {
    API.setHeaderAuthorization(null);
    dispatch({
      type: types.LOGOUT_REQUEST,
    });
  };
};
