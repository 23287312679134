import React from "react";
import datas from "../Data/PendingTaskData.json";
import { Button, Table } from "react-bootstrap";
import "./PendingTask.scss";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";

const PendingTask = () => {
  const navigate = useNavigate();
  const taskDetail = () => {
    navigate("/task-detail");
  };
  return (
    <div className="pending-task-screen">
      <Header />
      <div className="box-content">
        <h2 className="title">Total Pending Task : 6</h2>
        <Table className="task-table" responsive>
          <thead>
            <tr>
              <th>S/N</th>
              <th>Task Name</th>
              <th>Priority</th>
              <th>Due On</th>
              <th>Status</th>
              <th>#</th>
            </tr>
          </thead>
          <tbody>
            {datas.map((data) => (
              <tr key={data.sn}>
                <td>{data.sn}</td>
                <td>{data.taskName}</td>
                <td>{data.priority}</td>
                <td className="duo-on">{data.dueOn}</td>
                <td>{data.status}</td>
                <td>
                  <Button
                    className="pending-task-view-btn"
                    onClick={taskDetail}
                  >
                    View
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default PendingTask;
