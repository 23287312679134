import React, { useEffect, useState } from "react";
import cartIcon from "../../assets/logo/cart.png";
import logOutIcon from "../../assets/logo/logOut.png";
import { Button } from "react-bootstrap";
import "./HomeScreen.scss";
import { useNavigate } from "react-router-dom";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { logoutRequest } from "../../redux/actions/AuthAction";

const percentage = 66;

const HomeScreen = () => {
  const { user } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const [date, setDate] = useState(new Date());
  const navigate = useNavigate();
  const pendingTask = () => {
    navigate("/pending-task");
  };
  const logout = () => {
    dispatch(logoutRequest());
  };
  useEffect(() => {
    let timer = setInterval(() => setDate(new Date()), 1000);
    return function() {
      clearInterval(timer);
    };
  });
  return (
    <div className="dashboard-screen">
      <div className="header d-flex justify-content-center align-items-center">
        <div className="profile d-flex justify-content-between ms-auto">
          <div className="info">
            <h2>{user?.username}</h2>
            <h5>ID : 09125</h5>
          </div>
          <div className="time">
            <h2>{dayjs(date).format("h.mm a")}</h2>
            <h5>{dayjs(date).format("ddd MMMM DD YYYY")}</h5>
          </div>
        </div>
        <div className="logout ms-auto" onClick={logout}>
          <img src={logOutIcon} alt="" />
        </div>
      </div>
      <section className="section">
        <div className="card">
          <div className="content">
            <div className="title">Task Performance</div>
            <h4>
              Assigned <span className="orange">26</span>
            </h4>
            <h4>
              Complete <span className="green">20</span>
            </h4>
            <h4>
              Failed <span className="red">06</span>
            </h4>
          </div>
        </div>
        <div className="card">
          <div className="content">
            <div className="title">Performance Score</div>
            <div className="progress-bar">
              <CircularProgressbar
                value={percentage}
                styles={buildStyles({
                  strokeLinecap: "round",
                  textSize: "20px",
                  pathTransitionDuration: 0.5,
                  pathColor: `#0776B8`,
                  textColor: "#0776B8;",
                  trailColor: "#d6d6d6",
                  backgroundColor: "#3e98c7",
                })}
              />
              <div className="progress-bar-text">{`${percentage}%`}</div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="content">
            <div className="title">Next Task</div>
            <h4>1. Task Name</h4>
            <h4>2. Task Name</h4>
          </div>
        </div>
      </section>
      <Button className="view-task-btn" onClick={pendingTask}>
        <img src={cartIcon} alt="" /> Click here to View Your Task
      </Button>
    </div>
  );
};

export default HomeScreen;
